<template>
    <div>
        <el-row id="container-row">
            <el-col id="container-col">
                <el-row>
                    <el-col>
                        <h1>基本数据<el-link style="margin-left: 16px;" href="bigScreen/index.html" type="primary">数据大屏</el-link></h1>
                    </el-col>
                </el-row>
                <el-row type="flex" justify="center" :gutter="16">
                    <el-col :span="12">
                        <el-card :body-style="{padding: '0px'}" shadow="always">
                            <el-row type="flex" justify="center" style="background: #313e50; padding: 0">
                                <TDSBarChart style="width: 100%"/>
                            </el-row>
                            <div style="padding: 16px;">
                                <span>TDS指标</span>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card :body-style="{padding: '0px'}" shadow="always">
                            <el-row type="flex" justify="center" style="background: #313e50; padding: 0">
                                <ProblemPieChart style="width: 100%"/>
                            </el-row>
                            <div style="padding: 16px;">
                                <span>设备报修量</span>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row type="flex" justify="center" :gutter="16" style="margin-top: 16px">
                    <el-col :span="12">
                        <el-card :body-style="{padding: '0px'}" shadow="always">
                            <el-row type="flex" justify="center" style="background: #313e50; padding: 0">
                                <WorstDeviceLineChart style="width: 100%"/>
                            </el-row>
                            <div style="padding: 16px;">
                                <span>问题设备</span>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card :body-style="{padding: '0px'}" shadow="always">
                            <el-row type="flex" justify="center" style="background: #313e50; padding: 0">
                                <VolumeRankBarChart style="width: 80%"/>
                            </el-row>
                            <div style="padding: 16px;">
                                <span>售水量</span>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import TDSBarChart from "@/components/BasicData/TDSBarChart/TDSIndex.vue";
import ProblemPieChart from "@/components/BasicData/ProblemPieChart/ProblemIndex.vue";
import WorstDeviceLineChart from "@/components/BasicData/WorstDeviceLineChart/WorstDeviceIndex.vue";
import VolumeRankBarChart from "@/components/BasicData/VolumeRankBarChart/VolumeRankIndex.vue";
export default {
    components: {TDSBarChart, ProblemPieChart, WorstDeviceLineChart, VolumeRankBarChart},
    name: "BasicData",
    data: () => ({
        config: {
            radius: '80%',
            activeRadius: '90%',
            data: [
                {
                    name: '周口',
                    value: 55
                },
                {
                    name: '南阳',
                    value: 120
                },
                {
                    name: '西峡',
                    value: 78
                },
                {
                    name: '驻马店',
                    value: 66
                },
                {
                    name: '新乡',
                    value: 80
                }
            ]
        },
        tdsConfig: {}
    })
};
</script>

<style scoped>
#container-row {
    padding: 0 32px;
}

#container-col {
    padding: initial;
}
</style>