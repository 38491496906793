<template>
    <div>
        <el-row id="container-row">
            <el-col id="container-col">
                <el-row>
                    <el-col>
                        <h1>企业管理</h1>
                    </el-col>
                </el-row>
                <el-row type="flex" justify="center" :gutter="16">
                    <el-col :span="24">
                        <el-card :body-style="{padding: '0px'}" shadow="always">
                            <el-table
                                    :data="tableData"
                                    style="width: 100%"
                                    :row-class-name="tableRowClassName">
                                <el-table-column
                                        prop="id"
                                        label="企业ID">
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="企业名">
                                </el-table-column>
                                <el-table-column
                                        prop="owner"
                                        label="负责人">
                                </el-table-column>
                                <el-table-column
                                        prop="area"
                                        label="所在地区">
                                </el-table-column>
                                <el-table-column
                                        prop="registerTime"
                                        label="注册时间">
                                </el-table-column>
                                <el-table-column
                                        label="操作">
                                    <template slot="header">
                                        操作&nbsp;
                                        <el-button circle plain
                                                   size="mini"
                                                   type="primary"
                                                   icon="el-icon-plus"
                                                   @click="handleAdd">
                                        </el-button>
                                    </template>
                                    <template slot-scope="scope">
                                        <div>
                                            <el-button circle plain
                                                       size="mini"
                                                       type="primary"
                                                       icon="el-icon-edit"
                                                       @click="handleEdit(scope.$index)">
                                            </el-button>

                                            <el-button circle
                                                       size="mini"
                                                       type="danger"
                                                       icon="el-icon-delete"
                                                       @click="handleDelete(scope.$index)">
                                            </el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-dialog title="添加企业" :visible.sync="addMachineDialogVisible">

            <el-form :model="addMachineForm" style="width: 100%;">
                <el-form-item label="企业ID" :label-width="labelWidth">
                    <el-input class="dialog-form" v-model="addMachineForm.id" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="企业名" :label-width="labelWidth">
                    <el-input class="dialog-form" v-model="addMachineForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="负责人" :label-width="labelWidth">
                    <el-input class="dialog-form" v-model="addMachineForm.owner" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所在地区" :label-width="labelWidth">
                    <el-select class="dialog-form" v-model="addMachineForm.area" placeholder="请选择">
                        <el-option label="满城区" value="满城区"></el-option>
                        <el-option label="徐水区" value="徐水区"></el-option>
                        <el-option label="清苑区" value="清苑区"></el-option>
                        <el-option label="莲池区" value="莲池区"></el-option>
                        <el-option label="竞秀区" value="竞秀区"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="注册时间" :label-width="labelWidth">
                    <el-date-picker class="dialog-form" v-model="addMachineForm.registerTime"
                                    autocomplete="off"></el-date-picker>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="addMachineDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="addMachineDialogVisible = false">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="添加企业" :visible.sync="editMachineDialogVisible">

            <el-form :model="editMachineForm" style="width: 100%;">
                <el-form-item label="企业ID" :label-width="labelWidth">
                    <el-input class="dialog-form" v-model="editMachineForm.id" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="企业名" :label-width="labelWidth">
                    <el-input class="dialog-form" v-model="editMachineForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="负责人" :label-width="labelWidth">
                    <el-input class="dialog-form" v-model="editMachineForm.owner" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所在地区" :label-width="labelWidth">
                    <el-select class="dialog-form" v-model="editMachineForm.area" placeholder="请选择">
                        <el-option label="满城区" value="满城区"></el-option>
                        <el-option label="徐水区" value="徐水区"></el-option>
                        <el-option label="清苑区" value="清苑区"></el-option>
                        <el-option label="莲池区" value="莲池区"></el-option>
                        <el-option label="竞秀区" value="竞秀区"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="注册时间" :label-width="labelWidth">
                    <el-date-picker class="dialog-form" v-model="editMachineForm.registerTime"
                                    autocomplete="off"></el-date-picker>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="editMachineDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="editMachineDialogVisible = false">确定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="删除企业" :visible.sync="deleteMachineDialogVisible">
            <span style="color: red">注意：删除后不可恢复！</span>
            <p>{{ deleteMachineForm.id }}</p>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deleteMachineDialogVisible = false">取消</el-button>
                <el-button type="danger" @click="deleteMachineDialogVisible = false">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    name: "CompanyManage",
    methods: {
        tableRowClassName({row}) {
            if (parseInt(row.tds) > 45) {
                return 'warning-row';
            } else if (parseInt(row.tds) < 35) {
                return 'success-row';
            }
            return '';
        },
        handleAdd() {
            this.addMachineForm = {};
            this.addMachineDialogVisible = true;
        },
        handleEdit(index) {
            this.editMachineForm = this.tableData[index];
            this.editMachineDialogVisible = true;
        },
        handleDelete(index) {
            this.deleteMachineForm = {id: this.tableData[index].id};
            this.deleteMachineDialogVisible = true;
        }
    },
    data: () => ({
        addMachineForm: {},
        editMachineForm: {},
        deleteMachineForm: {},
        addMachineDialogVisible: false,
        editMachineDialogVisible: false,
        deleteMachineDialogVisible: false,
        labelWidth: '128px',
        tableData: [{
            "id": "SJLCQ0300030",
            "name": "1公司",
            "owner": "王1",
            "area": "满城区",
            "registerTime": "2023/3/9"
        }, {
            "id": "SJLCQ0300021",
            "name": "2公司",
            "owner": "王2",
            "area": "徐水区",
            "registerTime": "2023/2/28"
        }, {
            "id": "SJLCQ0300025",
            "name": "3公司",
            "owner": "王3",
            "area": "清苑区",
            "registerTime": "2023/3/4"
        }, {
            "id": "SJLCQ0300039",
            "name": "4公司",
            "owner": "王4",
            "area": "莲池区",
            "registerTime": "2023/3/18"
        }, {
            "id": "SJLCQ0300026",
            "name": "5公司",
            "owner": "王5",
            "area": "徐水区",
            "registerTime": "2023/3/5"
        }, {
            "id": "SJLCQ0300022",
            "name": "6公司",
            "owner": "王6",
            "area": "竞秀区",
            "registerTime": "2023/3/1"
        }, {
            "id": "SJLCQ0300032",
            "name": "7公司",
            "owner": "王7",
            "area": "竞秀区",
            "registerTime": "2023/3/11"
        }, {
            "id": "SJLCQ0300033",
            "name": "8公司",
            "owner": "王8",
            "area": "莲池区",
            "registerTime": "2023/3/12"
        }, {
            "id": "SJLCQ0300020",
            "name": "9公司",
            "owner": "王9",
            "area": "徐水区",
            "registerTime": "2023/2/27"
        }, {
            "id": "SJLCQ0300034",
            "name": "10公司",
            "owner": "王10",
            "area": "满城区",
            "registerTime": "2023/3/13"
        }, {
            "id": "SJLCQ0300031",
            "name": "11公司",
            "owner": "王11",
            "area": "徐水区",
            "registerTime": "2023/3/10"
        }, {
            "id": "SJLCQ0300027",
            "name": "12公司",
            "owner": "王12",
            "area": "清苑区",
            "registerTime": "2023/3/6"
        }, {
            "id": "SJLCQ0300036",
            "name": "13公司",
            "owner": "王13",
            "area": "满城区",
            "registerTime": "2023/3/15"
        }, {
            "id": "SJLCQ0300035",
            "name": "14公司",
            "owner": "王14",
            "area": "满城区",
            "registerTime": "2023/3/14"
        }, {
            "id": "SJLCQ0300023",
            "name": "15公司",
            "owner": "王15",
            "area": "竞秀区",
            "registerTime": "2023/3/2"
        }, {
            "id": "SJLCQ0300029",
            "name": "16公司",
            "owner": "王16",
            "area": "满城区",
            "registerTime": "2023/3/8"
        }, {
            "id": "SJLCQ0300019",
            "name": "17公司",
            "owner": "王17",
            "area": "莲池区",
            "registerTime": "2023/2/26"
        }, {
            "id": "SJLCQ0300028",
            "name": "18公司",
            "owner": "王18",
            "area": "徐水区",
            "registerTime": "2023/3/7"
        }, {
            "id": "SJLCQ0300024",
            "name": "19公司",
            "owner": "王19",
            "area": "竞秀区",
            "registerTime": "2023/3/3"
        }, {
            "id": "SJLCQ0300037",
            "name": "20公司",
            "owner": "王20",
            "area": "竞秀区",
            "registerTime": "2023/3/16"
        }, {"id": "SJLCQ0300038", "name": "21公司", "owner": "王21", "area": "竞秀区", "registerTime": "2023/3/17"}]
    })
};
</script>

<style scoped>
#container-row {
    padding: 0 32px;
}

#container-col {
    padding: initial;
}

.dialog-form {
    width: 80%;
}

</style>
<style>
.el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}
</style>