<template>
    <dv-scroll-ranking-board style="height: 300px; width: 200px;" :config="ranking" />
</template>

<script>
export default {
    data() {
        return {
            ranking: {
                data: [
                    {
                        name: 'SJLCQ0300015',
                        value: 55
                    },
                    {
                        name: 'SJLCQ0300016',
                        value: 96
                    },
                    {
                        name: 'SJLCQ0300017',
                        value: 78
                    },
                    {
                        name: 'SJLCQ0300018',
                        value: 66
                    },
                    {
                        name: 'SJLCQ0300019',
                        value: 80
                    },
                    {
                        name: 'SJXSQ0300015',
                        value: 57
                    },
                    {
                        name: 'SJXSQ0300016',
                        value: 134
                    },
                    {
                        name: 'SJXSQ0300017',
                        value: 10
                    },
                    {
                        name: 'SJXSQ0300018',
                        value: 63
                    },
                    {
                        name: 'SJXSQ0300019',
                        value: 54
                    }
                ],
                carousel: 'single',
                unit: '升'
            }
        }
    }
};
</script>

<style scoped>

</style>