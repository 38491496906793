<template>
  <div>
    <el-row id="container-row">
      <el-col id="container-col">
        <el-row>
          <el-col>
            <h1>监管日志</h1>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" :gutter="16">
          <el-col :span="24">
            <el-card :body-style="{padding: '0px'}" shadow="always">
              <el-table
                  :data="tableData"
                  style="width: 100%"
                  :row-class-name="tableRowClassName">
                <el-table-column
                    prop="id"
                    label="水机ID"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="manufacturer"
                    label="所属公司"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="tds"
                    label="最新TDS">
                </el-table-column>
                <el-table-column
                    prop="source"
                    label="来源">
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="监测时间">
                </el-table-column>
                <el-table-column
                    label="操作">

                  <template slot-scope="scope">
                    <div>
                      <!--                                            <el-button circle plain-->
                      <!--                                                       size="mini"-->
                      <!--                                                       type="primary"-->
                      <!--                                                       icon="el-icon-edit"-->
                      <!--                                                       @click="handleEdit(scope.$index)">-->
                      <!--                                            </el-button>-->

                      <el-button circle
                                 size="mini"
                                 type="danger"
                                 icon="el-icon-delete"
                                 @click="handleDelete(scope.$index)">
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog title="修改水机" :visible.sync="editLogDialogVisible">

      <el-form :model="editLogForm" style="width: 100%;">
        <el-form-item label="水机ID" :label-width="labelWidth">
          <el-input disabled class="dialog-form" v-model="editLogForm.id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属公司" :label-width="labelWidth">
          <el-select class="dialog-form" v-model="editLogForm.manufacturer" placeholder="请选择">
            <el-option label="A公司" value="A"></el-option>
            <el-option label="B公司" value="B"></el-option>
            <el-option label="C公司" value="C"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区" :label-width="labelWidth">
          <el-select class="dialog-form" v-model="editLogForm.area" placeholder="请选择">
            <el-option label="满城区" value="满城区"></el-option>
            <el-option label="徐水区" value="徐水区"></el-option>
            <el-option label="清苑区" value="清苑区"></el-option>
            <el-option label="莲池区" value="莲池区"></el-option>
            <el-option label="竞秀区" value="竞秀区"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属小区" :label-width="labelWidth">
          <el-input class="dialog-form" v-model="editLogForm.community" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安装时间" :label-width="labelWidth">
          <el-date-picker class="dialog-form" v-model="editLogForm.installTime"
                          autocomplete="off"></el-date-picker>
        </el-form-item>
        <el-form-item label="滤芯更换时间" :label-width="labelWidth">
          <el-date-picker class="dialog-form" v-model="editLogForm.renewTime"
                          autocomplete="off"></el-date-picker>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editLogDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editLogDialogVisible = false">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除水机" :visible.sync="deleteLogDialogVisible">
      <span style="color: red">注意：删除后不可恢复！</span>
      <p>{{ deleteLogForm.id }}</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteLogDialogVisible = false">取消</el-button>
        <el-button type="danger" @click="deleteLogDialogVisible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  name: "DetectLog",
  methods: {
    tableRowClassName({row}) {
      if (parseInt(row.tds) > 45) {
        return 'warning-row';
      } else if (parseInt(row.tds) < 35) {
        return 'success-row';
      }
      return '';
    },
    handleAdd() {
      this.addLogForm = {};
      this.addLogDialogVisible = true;
    },
    handleEdit(index) {
      this.editLogForm = this.tableData[index];
      this.editLogDialogVisible = true;
    },
    handleDelete(index) {
      this.deleteLogForm = {id: this.tableData[index].id};
      this.deleteLogDialogVisible = true;
    }
  },
  data: () => ({
    addLogForm: {},
    editLogForm: {},
    deleteLogForm: {},
    addLogDialogVisible: false,
    editLogDialogVisible: false,
    deleteLogDialogVisible: false,
    labelWidth: '128px',
    tableData: [{
      "id": "SJLCQ0300024",
      "manufacturer": "A公司",
      "tds": "35",
      "source": "人工",
      "time": "2023/4/7"
    }, {
      "id": "SJLCQ0300025",
      "manufacturer": "B公司",
      "tds": "36",
      "source": "自动",
      "time": "2023/4/8"
    }, {
      "id": "SJLCQ0300033",
      "manufacturer": "C公司",
      "tds": "44",
      "source": "自动",
      "time": "2023/4/16"
    }, {
      "id": "SJLCQ0300028",
      "manufacturer": "B公司",
      "tds": "39",
      "source": "人工",
      "time": "2023/4/11"
    }, {
      "id": "SJLCQ0300022",
      "manufacturer": "A公司",
      "tds": "33",
      "source": "人工",
      "time": "2023/4/5"
    }, {
      "id": "SJLCQ0300020",
      "manufacturer": "A公司",
      "tds": "31",
      "source": "人工",
      "time": "2023/4/3"
    }, {
      "id": "SJLCQ0300021",
      "manufacturer": "A公司",
      "tds": "32",
      "source": "人工",
      "time": "2023/4/4"
    }, {
      "id": "SJLCQ0300030",
      "manufacturer": "B公司",
      "tds": "41",
      "source": "人工",
      "time": "2023/4/13"
    }, {
      "id": "SJLCQ0300032",
      "manufacturer": "B公司",
      "tds": "43",
      "source": "人工",
      "time": "2023/4/15"
    }, {
      "id": "SJLCQ0300031",
      "manufacturer": "B公司",
      "tds": "42",
      "source": "自动",
      "time": "2023/4/14"
    }, {
      "id": "SJLCQ0300019",
      "manufacturer": "A公司",
      "tds": "30",
      "source": "自动",
      "time": "2023/4/2"
    }, {
      "id": "SJLCQ0300023",
      "manufacturer": "A公司",
      "tds": "34",
      "source": "人工",
      "time": "2023/4/6"
    }, {
      "id": "SJLCQ0300037",
      "manufacturer": "C公司",
      "tds": "48",
      "source": "自动",
      "time": "2023/4/20"
    }, {
      "id": "SJLCQ0300035",
      "manufacturer": "C公司",
      "tds": "46",
      "source": "自动",
      "time": "2023/4/18"
    }, {
      "id": "SJLCQ0300039",
      "manufacturer": "C公司",
      "tds": "50",
      "source": "人工",
      "time": "2023/4/22"
    }, {
      "id": "SJLCQ0300026",
      "manufacturer": "B公司",
      "tds": "37",
      "source": "人工",
      "time": "2023/4/9"
    }, {
      "id": "SJLCQ0300029",
      "manufacturer": "B公司",
      "tds": "40",
      "source": "自动",
      "time": "2023/4/12"
    }, {
      "id": "SJLCQ0300027",
      "manufacturer": "B公司",
      "tds": "38",
      "source": "自动",
      "time": "2023/4/10"
    }, {
      "id": "SJLCQ0300038",
      "manufacturer": "C公司",
      "tds": "49",
      "source": "人工",
      "time": "2023/4/21"
    }, {
      "id": "SJLCQ0300036",
      "manufacturer": "C公司",
      "tds": "47",
      "source": "人工",
      "time": "2023/4/19"
    }, {"id": "SJLCQ0300034", "manufacturer": "C公司", "tds": "45", "source": "自动", "time": "2023/4/17"}]
  })
};
</script>

<style scoped>
#container-row {
  padding: 0 32px;
}

#container-col {
  padding: initial;
}


</style>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>