<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './ProblemChart.vue';
export default {
  name: 'ProblemPieChart',
  data () {
    return {
      cdata: {
        xData: ["竞秀区", "莲池区", "满城区", "清苑区", "徐水区"],
        seriesData: [
          { value: 10, name: "竞秀区" },
          { value: 5, name: "莲池区" },
          { value: 15, name: "满城区" },
          { value: 25, name: "清苑区" },
          { value: 20, name: "徐水区" },
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>