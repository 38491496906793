<template>
    <div>
        <el-row id="container-row">
            <el-col id="container-col">
                <el-row>
                    <el-col>
                        <h1>法律法规</h1>
                    </el-col>
                </el-row>
                <el-row type="flex" justify="center" :gutter="16">
                    <el-col :span="24">
                        <el-card :body-style="{padding: '16px'}" shadow="always">
                            <iframe frameborder="no" width="100%" height="800px" src="pdfViewer/pdf.js/viewer.html?file=compressed.tracemonkey-pldi-09.pdf"></iframe>
                        </el-card>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>

</template>

<script>
export default {
    name: "ViewLaw",
    methods: {

    }
};
</script>

<style scoped>

</style>