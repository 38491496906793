<template>
  <div>
    <EChart
      :options="options"
      height="300px"
      width="100%"
    ></EChart>
  </div>
</template>

<script>
import EChart from '@/common/echart/EChartIndex.vue'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    EChart: EChart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            }
          },
          legend: {
            data: ["设备ID：SJLCQ0300014 合格率"],
            textStyle: {
              color: "#B4B4B4"
            },
            top: "10%"
          },
          grid: {
            x: "16%"
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            }
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            }
          ],
          series: [
            {
              name: "设备ID：SJLCQ0300014 合格率",
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              yAxisIndex: 0,
              itemStyle: {
                normal: {
                  color: "#f02f2f"
                }
              },
              data: newData.rateData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  }
}
</script>