<template>
  <VolumeRankChart></VolumeRankChart>
</template>

<script>
import VolumeRankChart from "@/components/BasicData/VolumeRankBarChart/VolumeRankChart.vue";
export default {
    name: "VolumeRankBarChart",
    components: {VolumeRankChart}
};
</script>

<style scoped>

</style>