<!--suppress JSValidateTypes -->
<template>
    <div id="app">
        <el-row id="row" type="flex" justify="center">
            <el-col id="col" :span="24" :lg="24">
                <el-container style="height: 100%">
                    <el-header id="header">
                        <el-menu mode="horizontal" background-color="#333" text-color="#fff">
                            <el-submenu id="title" index="0">
                                <template slot="title">保定市现制现售水监测平台</template>
                                <el-menu-item index="0-1">保定市</el-menu-item>
                                <el-menu-item index="0-2">XX县</el-menu-item>
                            </el-submenu>
                            <el-submenu index="1">
                                <template slot="title">用户管理</template>
                                <el-menu-item index="1-1">个人中心</el-menu-item>
                                <el-menu-item index="1-2" @click="handleLogout">退出登录</el-menu-item>
                            </el-submenu>
                        </el-menu>
                    </el-header>
                    <el-main id="header-main">
                        <el-container style="height: 100%">
                            <el-aside style="height: 100%" width="200px">
                                <el-menu id="sideBar" :default-openeds="['2']" style="height: 100%"
                                         default-active="/BasicData"
                                         @open="handleOpen" @close="handleClose" @select="handleSelect"
                                         background-color="#333" text-color="#fff" router>
                                    <el-menu-item index="/BasicData">
                                        <i class="el-icon-data-analysis"></i>
                                        基本数据
                                    </el-menu-item>
                                    <el-menu-item index="/DeviceManage">
                                        <i class="el-icon-edit-outline"></i>
                                        水机管理
                                    </el-menu-item>
                                    <el-menu-item index="/DetectLog">
                                        <i class="el-icon-view"></i>
                                        监管日志
                                    </el-menu-item>
                                    <el-menu-item index="/ManualDetect">
                                        <i class="el-icon-user"></i>
                                        人工监管
                                    </el-menu-item>
                                    <el-menu-item index="/CompanyManage">
                                        <i class="el-icon-office-building"></i>
                                        企业管理
                                    </el-menu-item>
                                    <el-menu-item index="/ViewLaw">
                                        <i class="el-icon-notebook-2"></i>
                                        法律法规
                                    </el-menu-item>
                                </el-menu>
                            </el-aside>
                            <el-main>
                                <router-view/>
                            </el-main>
                        </el-container>
                    </el-main>
                </el-container>
            </el-col>
        </el-row>

    </div>
</template>

<style lang="scss" scoped>
@import 'style/element-variables';

#title {
  color: $--color-primary;
}

#header, #header-main {
  padding: 0;
}

#row, #col {
  height: 100%;
}
</style>

<script>

export default {
    components: {},
    methods: {
        startHacking() {
            this.$notify({
                title: 'It works!',
                type: 'success',
                message: 'We\'ve laid the ground work for you. It\'s time for you to build something epic!',
                duration: 5000
            });
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        handleSelect(key) {
            this.sideBarSelected = key;
        },
        handleLogout() {
            window.location.href = '/login/login.html'
        }
    },
    data: () => ({
        sideBarSelected: '1'
    })
};
</script>
