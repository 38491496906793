<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }"/>
</template>

<script>
import tdTheme from './theme.json'; // 引入默认主题
import '../map/baoding.js';

export default {
    name: 'EChartIndex',
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '2.5rem'
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            chart: null
        };
    },
    watch: {
        options: {
            handler(options) {
                this.chart.setOption(options, true);
            },
            deep: true
        }
    },
    mounted() {
        this.$echarts.registerTheme('tdTheme', tdTheme); // 覆盖默认主题
        this.initChart();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
        this.chart.dispose();
        this.chart = null;
    },
    methods: {
        initChart() {
            this.chart = this.$echarts.init(this.$el, 'tdTheme');
            this.chart.setOption(this.options, true);
            window.addEventListener("resize", this.handleResize);
        },
        handleResize() {
            if (this.chart) {
                this.chart.resize();
            }
        }
    }
};
</script>

<style>
</style>
