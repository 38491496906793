<template>
    <div>
        <el-row id="container-row">
            <el-col id="container-col">
                <el-row>
                    <el-col>
                        <h1>人工监管</h1>
                    </el-col>
                </el-row>
                <el-row type="flex" justify="center" :gutter="16">
                    <el-col :span="24">
                        <el-card :body-style="{padding: '64px'}" shadow="always">
                            <el-form :model="addMachineForm" style="width: 100%;">
                                <el-form-item label="水机ID" :label-width="labelWidth">
                                    <el-input class="dialog-form" v-model="addMachineForm.id" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="所属公司" :label-width="labelWidth">
                                    <el-select class="dialog-form" v-model="addMachineForm.manufacturer" placeholder="请选择">
                                        <el-option label="A公司" value="A"></el-option>
                                        <el-option label="B公司" value="B"></el-option>
                                        <el-option label="C公司" value="C"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="所在地区" :label-width="labelWidth">
                                    <el-select class="dialog-form" v-model="addMachineForm.area" placeholder="请选择">
                                        <el-option label="满城区" value="满城区"></el-option>
                                        <el-option label="徐水区" value="徐水区"></el-option>
                                        <el-option label="清苑区" value="清苑区"></el-option>
                                        <el-option label="莲池区" value="莲池区"></el-option>
                                        <el-option label="竞秀区" value="竞秀区"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="所属小区" :label-width="labelWidth">
                                    <el-input class="dialog-form" v-model="addMachineForm.community" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="TDS" :label-width="labelWidth">
                                    <el-input class="dialog-form" v-model="addMachineForm.tds" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-form>
                            <el-button type="primary">提交</el-button>
                        </el-card>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    components: {},
    name: "ManualDetect",
    methods: {
        tableRowClassName({row}) {
            if (parseInt(row.tds) > 45) {
                return 'warning-row';
            } else if (parseInt(row.tds) < 35) {
                return 'success-row';
            }
            return '';
        },
        handleAdd() {
            this.addMachineForm = {};
            this.addMachineDialogVisible = true;
        },
        handleEdit(index) {
            this.editMachineForm = this.tableData[index];
            this.editMachineDialogVisible = true;
        },
        handleDelete(index) {
            this.deleteMachineForm = {id: this.tableData[index].id};
            this.deleteMachineDialogVisible = true;
        }
    },
    data: () => ({
        addMachineForm: {},
        labelWidth: '128px'
    })
};
</script>

<style scoped>
#container-row {
    padding: 0 32px;
}

#container-col {
    padding: initial;
}

.dialog-form {
    width: 80%;
}

</style>
<style>
.el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}
</style>