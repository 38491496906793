import Vue from 'vue';
import ElementUI from 'element-ui';
import './style/element-variables.scss';
import App from './App.vue';
import dataV from '@jiaminghi/data-view';
import echarts from 'echarts';
import VueRouter from 'vue-router';
import BasicData from "@/components/BasicData/BasicData.vue";
import DeviceManage from "@/components/DeviceManage/DeviceManage.vue";
import DetectLog from "@/components/DetectLog/DetectLog.vue";
import ManualDetect from "@/components/ManualDetect/ManualDetect.vue";
import CompanyManage from "@/components/CompanyManage/CompanyManage.vue";
import ViewLaw from "@/components/ViewLaw/ViewLaw.vue";
import axios from "axios";

const routes = [
    {path: '/', component: BasicData},
    {path: '/BasicData', component: BasicData},
    {path: '/DeviceManage', component: DeviceManage},
    {path: '/DetectLog', component: DetectLog},
    {path: '/ManualDetect', component: ManualDetect},
    {path: '/CompanyManage', component: CompanyManage},
    {path: '/ViewLaw', component: ViewLaw}
];

const router = new VueRouter({
    routes
});

Vue.prototype.$echarts = echarts;
Vue.use(VueRouter);
Vue.use(dataV);
Vue.use(ElementUI);

Vue.prototype.$axios = axios

Vue.prototype.$apiUrl = 'http://20.239.248.121:2084/'
// Vue.prototype.$apiUrl = '/devApi'

new Vue({
    router,
    el: '#app',
    render: h => h(App)
});