<template>
  <div>
    <el-row id="container-row">
      <el-col id="container-col">
        <el-row>
          <el-col>
            <h1>水机管理</h1>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-card>
              <p>筛选</p>
              <el-form :inline="true" :model="filterForm" size="small">
                <el-form-item label="水机ID">
                  <el-input v-model="filterForm.id" placeholder="水机ID"></el-input>
                </el-form-item>
                <el-form-item label="所属公司">
                  <el-input v-model="filterForm.manufacturer" placeholder="所属公司"></el-input>
                </el-form-item>
                <el-form-item label="所在地区">
                  <el-input v-model="filterForm.area" placeholder="所在地区">
                  </el-input>
                </el-form-item>
                <el-form-item label="所属小区">
                  <el-input v-model="filterForm.community" placeholder="所属小区"></el-input>
                </el-form-item>
                <el-form-item label="安装时间">
                  <el-col :span="11">
                    <el-date-picker type="date" placeholder="开始日期" v-model="filterForm.installTimeStart"
                                    style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                  </el-col>
                  <el-col :span="2">-</el-col>
                  <el-col :span="11">
                    <el-date-picker type="date" placeholder="结束日期" v-model="filterForm.installTimeEnd"
                                    style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="滤芯更换时间">
                  <el-col :span="11">
                    <el-date-picker type="date" placeholder="开始日期" v-model="filterForm.renewTimeStart"
                                    style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                  </el-col>
                  <el-col :span="2">-</el-col>
                  <el-col :span="11">
                    <el-date-picker type="date" placeholder="结束日期" v-model="filterForm.renewTimeEnd"
                                    style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="净水前TDS">
                  <el-col :span="10">
                    <el-input v-model="filterForm.rawtds[0]" placeholder="TDS"></el-input>
                  </el-col>
                  <el-col :span="2">
                    -
                  </el-col>
                  <el-col :span="10">
                    <el-input v-model="filterForm.rawtds[1]" placeholder="TDS"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="净水后TDS">
                  <el-col :span="10">
                    <el-input v-model="filterForm.puritytds[0]" placeholder="TDS"></el-input>
                  </el-col>
                  <el-col :span="2">
                    -
                  </el-col>
                  <el-col :span="10">
                    <el-input v-model="filterForm.puritytds[1]" placeholder="TDS"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-button @click="filterForm = {rawtds: [-1, -1], puritytds: [-1, -1]}; getDataAndCount(next, pageSize, filterForm)">清空筛选</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getDataAndCount(next, pageSize, filterForm)">筛选</el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>

        </el-row>
        <el-row type="flex" justify="center" :gutter="16">
          <el-col :span="24">
            <el-card :body-style="{padding: '0px'}" shadow="always">
              <center>
                <el-pagination
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    :total="totalCount"
                    @current-change="handlePageChange">
                </el-pagination>
              </center>

              <el-table
                  :data="tableData"
                  style="width: 100%"
                  :row-class-name="tableRowClassName">
                <el-table-column
                    prop="id"
                    label="水机ID">
                </el-table-column>
                <el-table-column
                    prop="manufacturer"
                    label="所属公司">
                </el-table-column>
                <el-table-column
                    prop="area"
                    label="所在地区">
                </el-table-column>
                <el-table-column
                    prop="community"
                    label="所属小区">
                </el-table-column>
                <el-table-column
                    prop="installTime"
                    label="安装时间">
                </el-table-column>
                <el-table-column
                    prop="renewTime"
                    label="滤芯更换时间">
                </el-table-column>
                <el-table-column
                    prop="rawtds"
                    label="净水前TDS">
                </el-table-column>
                <el-table-column
                    prop="puritytds"
                    label="净水后TDS">
                </el-table-column>
                <el-table-column
                    label="操作">
                  <template slot="header">
                    操作&nbsp;
                    <el-button circle plain
                               size="mini"
                               type="primary"
                               icon="el-icon-plus"
                               @click="handleAdd">
                    </el-button>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-button circle plain
                                 size="mini"
                                 type="primary"
                                 icon="el-icon-edit"
                                 @click="handleEdit(scope.$index)">
                      </el-button>

                      <el-button circle
                                 size="mini"
                                 type="danger"
                                 icon="el-icon-delete"
                                 @click="handleDelete(scope.$index)">
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog title="添加水机" :visible.sync="addMachineDialogVisible">

      <el-form :model="addMachineForm" style="width: 100%;">
        <el-form-item label="水机ID" :label-width="labelWidth">
          <el-input class="dialog-form" v-model="addMachineForm.id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属公司" :label-width="labelWidth">
          <el-select class="dialog-form" v-model="addMachineForm.manufacturer" placeholder="请选择">
            <el-option label="A公司" value="A"></el-option>
            <el-option label="B公司" value="B"></el-option>
            <el-option label="C公司" value="C"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区" :label-width="labelWidth">
          <el-select class="dialog-form" v-model="addMachineForm.area" placeholder="请选择">
            <el-option label="满城区" value="满城区"></el-option>
            <el-option label="徐水区" value="徐水区"></el-option>
            <el-option label="清苑区" value="清苑区"></el-option>
            <el-option label="莲池区" value="莲池区"></el-option>
            <el-option label="竞秀区" value="竞秀区"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属小区" :label-width="labelWidth">
          <el-input class="dialog-form" v-model="addMachineForm.community" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安装时间" :label-width="labelWidth">
          <el-date-picker class="dialog-form" v-model="addMachineForm.installTime"
                          autocomplete="off" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="滤芯更换时间" :label-width="labelWidth">
          <el-date-picker class="dialog-form" v-model="addMachineForm.renewTime"
                          autocomplete="off" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addMachineDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitAdd">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改水机" :visible.sync="editMachineDialogVisible">

      <el-form :model="editMachineForm" style="width: 100%;">
        <el-form-item label="水机ID" :label-width="labelWidth">
          <el-input disabled class="dialog-form" v-model="editMachineForm.id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属公司" :label-width="labelWidth">
          <el-select class="dialog-form" v-model="editMachineForm.manufacturer" placeholder="请选择">
            <el-option label="A公司" value="A"></el-option>
            <el-option label="B公司" value="B"></el-option>
            <el-option label="C公司" value="C"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区" :label-width="labelWidth">
          <el-select class="dialog-form" v-model="editMachineForm.area" placeholder="请选择">
            <el-option label="满城区" value="满城区"></el-option>
            <el-option label="徐水区" value="徐水区"></el-option>
            <el-option label="清苑区" value="清苑区"></el-option>
            <el-option label="莲池区" value="莲池区"></el-option>
            <el-option label="竞秀区" value="竞秀区"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属小区" :label-width="labelWidth">
          <el-input class="dialog-form" v-model="editMachineForm.community" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安装时间" :label-width="labelWidth">
          <el-date-picker class="dialog-form" v-model="editMachineForm.installTime"
                          autocomplete="off" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="滤芯更换时间" :label-width="labelWidth">
          <el-date-picker class="dialog-form" v-model="editMachineForm.renewTime"
                          autocomplete="off" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editMachineDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitEdit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除水机" :visible.sync="deleteMachineDialogVisible">
      <span style="color: red">注意：删除后不可恢复！</span>
      <p>{{ deleteMachineForm.id }}</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteMachineDialogVisible = false">取消</el-button>
        <el-button type="danger" @click="submitDelete()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  components: {},
  name: "DeviceManage",
  methods: {
    tableRowClassName({row}) {
      if (parseInt(row.tds) > 45) {
        return 'warning-row';
      } else if (parseInt(row.tds) < 35) {
        return 'success-row';
      }
      return '';
    },
    handleAdd() {
      this.addMachineForm = {};
      this.addMachineDialogVisible = true;
    },
    submitAdd() {
      let that = this
      that.$axios({
        url: that.$apiUrl + '/api/v1/machine',
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        data: JSON.stringify(that.addMachineForm)
      }).then(function (){
        that.addMachineDialogVisible = false
        that.getDataAndCount(that.next, that.pageSize, that.filterForm)
      })
    },
    handleEdit(index) {
      this.editMachineForm = JSON.parse(JSON.stringify(this.tableData[index]))
      delete this.editMachineForm.rawtds
      delete this.editMachineForm.puritytds
      this.editMachineDialogVisible = true;
    },
    submitEdit() {
      let that = this
      that.$axios({
        url: that.$apiUrl + '/api/v1/machine',
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        data: JSON.stringify(that.editMachineForm)
      }).then(function (){
        that.editMachineDialogVisible = false
        that.getDataAndCount(that.next, that.pageSize, that.filterForm)
      })
    },
    handleDelete(index) {
      this.deleteMachineForm = {id: this.tableData[index].id};
      this.deleteMachineDialogVisible = true;
    },
    submitDelete() {
      let that = this
      that.$axios({
        url: that.$apiUrl + '/api/v1/machine',
        method: "DELETE",
        params: {id: that.deleteMachineForm.id}
      }).then(function (){
        that.deleteMachineDialogVisible = false
        that.getDataAndCount(that.next, that.pageSize, that.filterForm)
      })
    },
    getData(from, num, filter) {
      let that = this
      let data = {
        from: from,
        num: num
      }
      Object.assign(data, filter)
      that.$axios({
        url: that.$apiUrl + '/api/v1/machine/filter',
        method: "POST",
        data: data,
      }).then(function (result) {
        that.tableData = result.data
      })
    },
    getCount(filter) {
      let that = this
      let data = filter
      Object.assign(data, filter)
      that.$axios({
        url: that.$apiUrl + '/api/v1/machine/filter/count',
        method: "POST",
        data: data,
      }).then(function (result) {
        that.totalCount = result.data.num
      })
    },
    getDataAndCount(from, num, filter){
      this.getCount(filter)
      this.getData(from, num, filter)
    },
    handlePageChange(value) {
      this.next = (value - 1) * this.pageSize
      this.getData(this.next, this.pageSize, this.filterForm)
    }
  },
  data: () => ({
    filterForm: {
      rawtds: [-1, -1],
      puritytds: [-1, -1]
    },
    addMachineForm: {},
    editMachineForm: {},
    deleteMachineForm: {},
    addMachineDialogVisible: false,
    editMachineDialogVisible: false,
    deleteMachineDialogVisible: false,
    totalCount: 0,
    next: 0,
    pageSize: 10,
    labelWidth: '128px',
    tableDataDev: [{
      "id": "SJLCQ0300030",
      "manufacturer": "B公司",
      "community": "Y小区",
      "area": "满城区",
      "installTime": "2022/11/30",
      "renewTime": "2023/3/9",
      "tds": "35"
    }, {
      "id": "SJLCQ0300021",
      "manufacturer": "A公司",
      "community": "Y小区",
      "area": "徐水区",
      "installTime": "2022/11/21",
      "renewTime": "2023/2/28",
      "tds": "36"
    }, {
      "id": "SJLCQ0300025",
      "manufacturer": "B公司",
      "community": "Z小区",
      "area": "清苑区",
      "installTime": "2022/11/25",
      "renewTime": "2023/3/4",
      "tds": "44"
    }, {
      "id": "SJLCQ0300039",
      "manufacturer": "C公司",
      "community": "X小区",
      "area": "莲池区",
      "installTime": "2022/12/9",
      "renewTime": "2023/3/18",
      "tds": "39"
    }, {
      "id": "SJLCQ0300026",
      "manufacturer": "B公司",
      "community": "X小区",
      "area": "徐水区",
      "installTime": "2022/11/26",
      "renewTime": "2023/3/5",
      "tds": "33"
    }, {
      "id": "SJLCQ0300022",
      "manufacturer": "A公司",
      "community": "X小区",
      "area": "竞秀区",
      "installTime": "2022/11/22",
      "renewTime": "2023/3/1",
      "tds": "31"
    }, {
      "id": "SJLCQ0300032",
      "manufacturer": "B公司",
      "community": "Y小区",
      "area": "竞秀区",
      "installTime": "2022/12/2",
      "renewTime": "2023/3/11",
      "tds": "32"
    }, {
      "id": "SJLCQ0300033",
      "manufacturer": "C公司",
      "community": "Y小区",
      "area": "莲池区",
      "installTime": "2022/12/3",
      "renewTime": "2023/3/12",
      "tds": "41"
    }, {
      "id": "SJLCQ0300020",
      "manufacturer": "A公司",
      "community": "Y小区",
      "area": "徐水区",
      "installTime": "2022/11/20",
      "renewTime": "2023/2/27",
      "tds": "43"
    }, {
      "id": "SJLCQ0300034",
      "manufacturer": "C公司",
      "community": "Z小区",
      "area": "满城区",
      "installTime": "2022/12/4",
      "renewTime": "2023/3/13",
      "tds": "42"
    }, {
      "id": "SJLCQ0300031",
      "manufacturer": "B公司",
      "community": "Z小区",
      "area": "徐水区",
      "installTime": "2022/12/1",
      "renewTime": "2023/3/10",
      "tds": "30"
    }, {
      "id": "SJLCQ0300027",
      "manufacturer": "B公司",
      "community": "Z小区",
      "area": "清苑区",
      "installTime": "2022/11/27",
      "renewTime": "2023/3/6",
      "tds": "34"
    }, {
      "id": "SJLCQ0300036",
      "manufacturer": "C公司",
      "community": "X小区",
      "area": "满城区",
      "installTime": "2022/12/6",
      "renewTime": "2023/3/15",
      "tds": "48"
    }, {
      "id": "SJLCQ0300035",
      "manufacturer": "C公司",
      "community": "Z小区",
      "area": "满城区",
      "installTime": "2022/12/5",
      "renewTime": "2023/3/14",
      "tds": "46"
    }, {
      "id": "SJLCQ0300023",
      "manufacturer": "A公司",
      "community": "Y小区",
      "area": "竞秀区",
      "installTime": "2022/11/23",
      "renewTime": "2023/3/2",
      "tds": "50"
    }, {
      "id": "SJLCQ0300029",
      "manufacturer": "B公司",
      "community": "Z小区",
      "area": "满城区",
      "installTime": "2022/11/29",
      "renewTime": "2023/3/8",
      "tds": "37"
    }, {
      "id": "SJLCQ0300019",
      "manufacturer": "A公司",
      "community": "Y小区",
      "area": "莲池区",
      "installTime": "2022/11/19",
      "renewTime": "2023/2/26",
      "tds": "40"
    }, {
      "id": "SJLCQ0300028",
      "manufacturer": "B公司",
      "community": "Y小区",
      "area": "徐水区",
      "installTime": "2022/11/28",
      "renewTime": "2023/3/7",
      "tds": "38"
    }, {
      "id": "SJLCQ0300024",
      "manufacturer": "A公司",
      "community": "X小区",
      "area": "竞秀区",
      "installTime": "2022/11/24",
      "renewTime": "2023/3/3",
      "tds": "49"
    }, {
      "id": "SJLCQ0300037",
      "manufacturer": "C公司",
      "community": "Z小区",
      "area": "竞秀区",
      "installTime": "2022/12/7",
      "renewTime": "2023/3/16",
      "tds": "47"
    }, {
      "id": "SJLCQ0300038",
      "manufacturer": "C公司",
      "community": "Y小区",
      "area": "竞秀区",
      "installTime": "2022/12/8",
      "renewTime": "2023/3/17",
      "tds": "45"
    }],
    tableData: []
  }),
  mounted() {
    this.getDataAndCount(this.next, this.pageSize, this.filterForm)
  }
};
</script>

<style scoped>
#container-row {
  padding: 0 32px;
}

#container-col {
  padding: initial;
}

.dialog-form {
  width: 80%;
}

</style>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>